import React from 'react';
import styles from '../../styles/pages/auth/Banner.module.css';

const Banner = () => {

  return (
    <div className={styles.bannerContainer}>
          <div className={styles.parallaxLayer}></div>
          <div className={styles.parallaxContent}>
            <h1 className={styles.title}>We're Launching Soon </h1>
            {/* <p className={styles.subtitle}>
              Sign up for our newsletter and stay updated with the latest news.
            </p> */}
            <div className={styles.newsletterForm}>
              <input
                type="email"
                placeholder="Enter your email address"
                className={styles.newsletterInput}
              />
              <button className={styles.newsletterButton}>Stay Updated</button>
            </div>
          </div>
    </div>
  );
};

export default Banner;
